import React, {useEffect, useMemo, useState} from 'react';
import TableContainer from 'Common/TableContainer';
import {Card, Col, Container, Dropdown} from 'react-bootstrap';
import ModalContainer from 'Common/ModalContainer';
import WareHouseAddUser from './WareHouseAddUser';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';
import WareHouseEditUser from './WareHouseEditUser';
import Swal from 'sweetalert2';
import {variables} from 'utils/constant';
import {DOCUMENT_TITLE} from 'Common/constants/layout';

interface UserData {
  data: {
    data:
      | Array<{
          id: number;
          userID: string;
          first_name: string;
          last_name: string;
          image: string;
          email: string;
          status_id: number;
          role_id: number;
          phone: number;
        }>
      | undefined;
    page_size?: number;
    current_page?: number;
    total?: number;
  };
  message?: string;
}

const WareHouseUserList = (): JSX.Element => {
  document.title = DOCUMENT_TITLE.WARE_HOUSE_LIST;
  const [userlist, setUserlist] = React.useState<UserData | undefined>();
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlagEdit, setModalFlagEdit] = useState(false);
  const [editUserId, setEditUserId] = useState<any>(null);
  const defaultPage = 1;
  const [pageValue, setPageValue] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState('id');

  const [sortDirection, setSortDirection] = useState('asc');
  const [search, setSearch] = useState<string>('');
  const [resetSearchFlag, setResetSearchFlag] = useState(false);

  const [totalRecords, setTotalRecords] = useState<number>(defaultPage);
  const currentData = Math.ceil(totalRecords / pageSize);

  useEffect(() => {
    void getUsersList();
  }, [pageValue, pageSize, search]);
  async function getUsersList(): Promise<void> {
    try {
      const data = await ApiUtils.getWarehouseList(
        `&page_size=${pageSize}&page=${pageValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&search=${search}`,
      );

      setUserlist(data as UserData);
      setTotalRecords((data as UserData)?.data?.total ?? defaultPage);
    } catch (error: any) {
      toast.error(error.message);
    }
  }
  const handleSearch = (value: string): void => {
    setSearch(value);
  };
  const handleNextPagination = (pageSize: any): void => {
    if (currentData > 0 && currentData > pageSize) {
      setPageValue(pageSize + 1);
    }
  };

  const handlePrevPagination = (pageSize: any): void => {
    if (pageSize > 1 && currentData >= pageSize) {
      setPageValue(pageSize - 1);
    }
  };
  const handleDelete = (id: any): void => {
    void Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (result.isConfirmed) {
        ApiUtils.deleteWarehouse(id)
          .then((data: any) => {
            setResetSearchFlag(true);
            setSearch('');
            // Update state directly after successful deletion
            setUserlist(prevUserList => {
              const updatedData = prevUserList?.data?.data?.filter(
                user => user.id !== id,
              );
              return {
                ...prevUserList,
                data: {
                  ...prevUserList?.data,
                  data: updatedData,
                },
              };
            });

            void Swal.fire({
              title: 'Deleted!',
              text: data?.message,
              icon: 'success',
            });
          })
          .catch(error => {
            toast.error(error.message);
          });
      }
    });
  };
  const handleSortByColumn = async (column: string): Promise<void> => {
    try {
      let newSortDirection = 'asc';

      if (column === sortColumn) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        newSortDirection = 'desc';
      }

      const data = await ApiUtils.getWarehouseList(
        `&page_size=${pageSize}&page=${pageValue}&sort_column=${column}&sort_direction=${newSortDirection}&search=${search}`,
      );

      setSortDirection(newSortDirection);
      setSortColumn(column);
      setUserlist(data as UserData);
      setTotalRecords((data as UserData)?.data?.total ?? defaultPage);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'User ID',
        disableFilters: true,
        filterable: true,
        accessor: 'id',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },

      {
        Header: 'First Name',
        disableFilters: true,
        filterable: true,
        accessor: 'first_name',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: ' Last Name',
        disableFilters: true,
        filterable: true,
        accessor: 'last_name',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },

      {
        Header: 'Email',
        disableFilters: true,
        filterable: true,
        accessor: 'email',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Phone Number',
        disableFilters: true,
        filterable: true,
        accessor: 'phone',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },

      {
        Header: 'Status',
        accessor: 'status_id',
        disableFilters: true,
        filterable: false,
        Cell: ({cell}: {cell: {value: number}}) => {
          const isActive = cell.value === variables.ACTIVE_VALUE;

          return (
            <span
              className={`badge text-uppercase ${
                isActive
                  ? 'bg-success-subtle text-success'
                  : 'bg-danger-subtle text-danger'
              }`}>
              {isActive ? 'Active' : 'Inactive'}
            </span>
          );
        },
      },
      {
        Header: 'Action',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          const handleDeleteClick = (): void => {
            const id = cellProps.row.original.id;
            handleDelete(id);
          };
          return (
            <Dropdown>
              <Dropdown.Toggle className="btn btn-soft-secondary btn-sm dropdown btn-icon arrow-none">
                <i className="ri-more-fill align-middle"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item
                    href="#"
                    className="remove-list"
                    onClick={() => {
                      modalToggleEdit(cellProps.row.original);
                    }}>
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                    Edit
                  </Dropdown.Item>
                </li>

                <Dropdown.Divider />
                <li>
                  <Dropdown.Item
                    href="#"
                    className="remove-list"
                    onClick={handleDeleteClick}>
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />
                    Delete
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [userlist],
  );

  // Add Modal
  function modalToggle(): void {
    setModalFlag(!modalFlag);
  }

  // Edit Modal
  function modalToggleEdit(id: any): void {
    // Set the ID in the component state or perform any other necessary logic
    setModalFlagEdit(!modalFlagEdit);
    setEditUserId(id);
  }

  return (
    <div className="page-content" data-testid="warehouse">
      <Container fluid>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0">WAREHOUSE</h4>
        </div>
        <Col xxl={12}>
          <Card>
            <Card.Body className="position-relative">
              <TableContainer
                columns={columns}
                data={userlist?.data?.data ?? []}
                isGlobalFilter={true}
                handleNextPagination={handleNextPagination}
                handlePrevPagination={handlePrevPagination}
                pageValue={pageValue}
                iscustomPageSize={false}
                isBordered={true}
                customPageSize={pageSize}
                pageSize={pageSize}
                currentData={currentData}
                setPageSize={setPageSize}
                isAddOptions={true}
                className="custom-header-css"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder="Search Warehouse..."
                buttonText="Add Warehouse"
                onClick={() => {
                  modalToggle();
                }}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handleSortByColumn={handleSortByColumn}
                onSearch={handleSearch}
                resetSearchFlag={resetSearchFlag}
              />
            </Card.Body>
          </Card>

          {/* Add Modal */}
          <ModalContainer
            showModal={modalFlag}
            handleClose={modalToggle}
            modalTitle="Add Warehouse User"
            modalBody={
              <WareHouseAddUser
                handleClose={modalToggle}
                getUsersList={getUsersList}
              />
            }
          />

          {/* Edit Modal */}
          <ModalContainer
            showModal={modalFlagEdit}
            handleClose={() => {
              modalToggleEdit(editUserId);
            }}
            modalTitle="Edit Warehouse User"
            modalBody={
              <WareHouseEditUser
                handleClose={() => {
                  modalToggleEdit(editUserId);
                }}
                warehouseId={editUserId}
                getUsersList={getUsersList}
              />
            }
          />
        </Col>
      </Container>
    </div>
  );
};

export default WareHouseUserList;
