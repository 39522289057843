/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/react-in-jsx-scope */
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React from 'react';

const PaymentDetailsModal = ({show, onHide, paymentId}: any): JSX.Element => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-3">Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <h5 className="mb-2 fs-5">
            <span className="fw-semibold">Amount:</span> ₹
            {Number(paymentId?.payment_detail?.amount) / 100}
          </h5>
          <h6 className="mb-2 fs-5">
            <span className="fw-semibold">Amount Refunded:</span>
            {/* ₹{paymentId?.payment_detail?.amount_refunded} */}
            <span className="">
              {paymentId?.refund_id !== null ? ' Yes' : ' No'}
            </span>
          </h6>
          <p className="mb-1 fs-5">
            <span className="fw-semibold">Order Id:-</span>{' '}
            {paymentId?.payment_detail?.order_id}
          </p>

          {/* If the method of payment is from card */}
          {paymentId?.payment_detail?.method === 'card' && (
            <>
              <div className="mb-4 mt-4">
                <h5 className="mb-2 fs-5">
                  <u>
                    <span className="fw-semibold">Payment by Card</span>
                  </u>
                </h5>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Card Id:-</span>{' '}
                  {paymentId?.payment_detail?.card?.id}
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">EMI:-</span>{' '}
                  {paymentId?.payment_detail?.card?.emi === true ? 'Yes' : 'No'}
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Entity:-</span>{' '}
                  {paymentId?.payment_detail?.card?.entity}
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Last 4 Digits:-</span>{' '}
                  {paymentId?.payment_detail?.card?.last4}
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Type of Card:-</span>{' '}
                  {paymentId?.payment_detail?.card?.type} card
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Card Holder Name:-</span>{' '}
                  {paymentId?.payment_detail?.card?.name || 'Account Holder'}
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Network:-</span>{' '}
                  {paymentId?.payment_detail?.card?.network}
                </p>
              </div>
            </>
          )}

          {/* If the method of payment is from netbanking */}
          {paymentId?.payment_detail?.method === 'netbanking' && (
            <>
              <div className="mb-4 mt-4">
                <h5 className="mb-2 fs-5">
                  <u>
                    <span className="fw-semibold">Payment by Net Banking</span>
                  </u>
                </h5>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Bank:-</span>{' '}
                  {paymentId?.payment_detail?.bank}
                </p>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Bank Transaction Id:-</span>{' '}
                  {
                    paymentId?.payment_detail?.acquirer_data
                      ?.bank_transaction_id
                  }
                </p>
              </div>
            </>
          )}
          {/* If the payment method is wallet */}
          {paymentId?.payment_detail?.method === 'wallet' && (
            <>
              <div className="mb-4 mt-4">
                <h5 className="mb-2 fs-5">
                  <u>
                    <span className="fw-semibold">Payment by Wallet</span>
                  </u>
                </h5>
                <p className="mb-1 fs-5">
                  <span className="fw-semibold">Wallet:-</span>{' '}
                  {paymentId?.payment_detail?.wallet}
                </p>
              </div>
            </>
          )}
        </div>

        <div className="mb-4">
          <h6 className="mb-2 fs-5">
            <span className="fw-semibold">Contact:</span>{' '}
            {paymentId?.payment_detail?.contact}
          </h6>
          <h6 className="mb-2 fs-5">
            <span className="fw-semibold">Email:</span>{' '}
            {paymentId?.payment_detail?.email}
          </h6>
          <h6 className="mb-2 fs-5">
            <span className="fw-semibold">Description:</span>{' '}
            {paymentId?.payment_detail?.description}
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PaymentDetailsModal;
