import React, {useEffect, useState} from 'react';
import {Form, Col, Row, Button} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {variables} from 'utils/constant';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';

interface WarehouseData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  status_id: string;
  warehouse_address: {
    country_id: string;
    state_id: string;
    id: number;
    address_line_1: string;
    address_line_2: string;
    postcode: string;
    landmark: string;
    city_id: string;
  };
}

const WareHouseEditUser: React.FC<{
  handleClose: () => void;
  getUsersList: () => Promise<void>;
  warehouseId: WarehouseData;
}> = ({handleClose, getUsersList, warehouseId}) => {
  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const fetchCountryData = async (): Promise<void> => {
    const response: any = await ApiUtils.getCountries();
    setCountryData(response?.data);
  };

  useEffect(() => {
    void fetchCountryData();
  }, []);
  const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string()
      .required('Phone is required')
      .test('isTenDigit', 'Phone must be 10 digits', value =>
        /^\d{10}$/.test(value),
      ),
    status_id: Yup.number().required('Status is required'),
    address_line_1: Yup.string().required('Address Line1 is required'),
    address_line_2: Yup.string().required('Address Line2 is required'),
    postcode: Yup.number()
      .typeError('Postcode must be a number')
      .test(
        'len',
        'Postcode must be exactly 6 digits',
        value => value?.toString().length === 6,
      )
      .required('Postcode is required'),

    landmark: Yup.string().required('Landmark is required'),
    city_id: Yup.string().required('City is required'),
    state_id: Yup.string().required('State is required'),
    country_id: Yup.string().required('Country is required'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: warehouseId.first_name ?? '',
      last_name: warehouseId.last_name ?? '',
      email: warehouseId.email ?? '',
      phone: warehouseId.phone ?? '',
      status_id: warehouseId.status_id ?? '',
      address_line_1: warehouseId?.warehouse_address?.address_line_1 ?? '',
      address_line_2: warehouseId?.warehouse_address?.address_line_2 ?? '',
      postcode: warehouseId?.warehouse_address?.postcode ?? '',
      landmark: warehouseId?.warehouse_address?.landmark ?? '',
      city_id: warehouseId?.warehouse_address?.city_id ?? '',
      state_id: warehouseId?.warehouse_address?.state_id ?? '',
      country_id: warehouseId?.warehouse_address?.country_id ?? '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const updateResponse: any = await ApiUtils.updateWarehouse({
          id: warehouseId.id, // Assuming there is an 'id' field in your warehouse data
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
          status_id: values.status_id,
          address: {
            id: warehouseId?.warehouse_address?.id,
            address_line_1: values.address_line_1,
            address_line_2: values.address_line_2,
            city_id: values.city_id,
            postcode: `${values.postcode}`,
            landmark: values.landmark,
          },
        });
        handleClose();
        void getUsersList();
        toast.success(updateResponse.message);
      } catch (error: any | unknown) {
        // Handle error
        // toast.error('An unexpected error occurred.');
        if (
          Boolean(error.response) &&
          typeof error.response.data === 'object' &&
          'message' in error.response.data
        ) {
          toast.error(error?.response?.data?.message);
          // handleClose();
        } else {
          // Something happened in setting up the request that triggered an Error

          toast.error('An unexpected error occurred.');
          handleClose();
        }
      }
    },
  });

  useEffect(() => {
    async function fetchStatesByCountry(): Promise<void> {
      try {
        const response: any = await ApiUtils.getStates(
          `${formik.values.country_id}`,
        );
        setStateData(response?.data);
      } catch (_err) {}
    }
    if (formik.values.country_id !== '') {
      void fetchStatesByCountry();
    }
  }, [formik.values.country_id]);

  useEffect(() => {
    async function fetchCitiesByState(): Promise<void> {
      try {
        const response: any = await ApiUtils.getCityData(
          `${formik.values.state_id}`,
        );
        setCityData(response?.data);
      } catch (_err) {}
    }
    if (formik.values.state_id !== '') {
      void fetchCitiesByState();
    }
  }, [formik.values.state_id]);

  useEffect(() => {
    if (
      formik.values.country_id !== warehouseId?.warehouse_address?.country_id
    ) {
      setCityData([]);
      setStateData([]);
    }
    if (formik.values.state_id !== warehouseId?.warehouse_address?.state_id) {
      setCityData([]);
    }
  }, [formik.values.country_id, formik.values.state_id]);

  useEffect(() => {
    // Prefill the form with existing data
    void formik.setValues({
      first_name: warehouseId.first_name ?? '',
      last_name: warehouseId.last_name ?? '',
      email: warehouseId.email ?? '',
      phone: warehouseId.phone ?? '',
      status_id: warehouseId.status_id ?? '',
      address_line_1: warehouseId?.warehouse_address?.address_line_1 ?? '',
      address_line_2: warehouseId?.warehouse_address?.address_line_2 ?? '',
      postcode: warehouseId?.warehouse_address?.postcode ?? '',
      landmark: warehouseId?.warehouse_address?.landmark ?? '',
      city_id: warehouseId?.warehouse_address?.city_id ?? '',
      state_id: warehouseId?.warehouse_address?.state_id ?? '',
      country_id: warehouseId?.warehouse_address?.country_id ?? '',
    });
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}>
      <Row>
        {/* Your form fields */}
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="first_name">First Name</Form.Label>
            <Form.Control
              type="text"
              id="first_name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.first_name}
              isInvalid={
                (formik.touched.first_name ?? false) &&
                !(formik.errors.first_name == null)
              }
            />
            {(formik.touched.first_name ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="last_name">Last Name</Form.Label>
            <Form.Control
              type="text"
              id="last_name"
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              isInvalid={
                (formik.touched.last_name ?? false) &&
                !(formik.errors.first_name == null)
              }
            />
            {(formik.touched.last_name ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="last_name">Email Address</Form.Label>
            <Form.Control
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              isInvalid={
                (formik.touched.email ?? false) &&
                !(formik.errors.email == null)
              }
            />
            {(formik.touched.email ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="phone">Phone Number</Form.Label>
            <Form.Control
              type="number"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              isInvalid={
                (formik.touched.phone ?? false) &&
                !(formik.errors.phone == null)
              }
              maxLength={10}
            />
            {(formik.touched.phone ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="postcode">Postcode</Form.Label>
            <Form.Control
              type="text"
              id="postcode"
              name="postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postcode}
              isInvalid={
                (formik.touched.postcode ?? false) &&
                !(formik.errors.postcode == null)
              }
              onKeyDown={e => {
                // Prevent 'e', 'E', '-', '+' and any non-numeric keys except Backspace and Delete
                if (
                  ['e', 'E', '+', '-'].includes(e.key) ||
                  (!/[0-9]/.test(e.key) &&
                    e.key !== 'Backspace' &&
                    e.key !== 'Delete')
                ) {
                  e.preventDefault();
                }
              }}
            />
            {(formik.touched.postcode ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.postcode}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="city_id">Country</Form.Label>
            <Form.Select
              as="select"
              id="country_id"
              name="country_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country_id}
              isInvalid={
                (formik.touched.country_id ?? false) &&
                !(formik.errors.country_id == null)
              }>
              <option value="" label="Select Country" />
              {countryData?.map((el: any) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
            {(formik.touched.country_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.country_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="city_id">State</Form.Label>
            <Form.Select
              as="select"
              id="state_id"
              name="state_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state_id}
              isInvalid={
                (formik.touched.state_id ?? false) &&
                !(formik.errors.state_id == null)
              }>
              <option value="" label="Select State" />
              {stateData?.map((el: any) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
            {(formik.touched.state_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.state_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="city_id">City</Form.Label>
            <Form.Select
              as="select"
              id="city_id"
              name="city_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city_id}
              isInvalid={
                (formik.touched.city_id ?? false) &&
                !(formik.errors.city_id == null)
              }>
              <option value="" label="Select city" />
              {cityData?.map((el: any) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
            {(formik.touched.city_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.city_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="address_line_1">Address Line1</Form.Label>
            <Form.Control
              type="text"
              id="address_line_1"
              name="address_line_1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address_line_1}
              isInvalid={
                (formik.touched.address_line_1 ?? false) &&
                !(formik.errors.address_line_1 == null)
              }
            />
            {(formik.touched.address_line_1 ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_line_1}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="address_line_2">Address Line2</Form.Label>
            <Form.Control
              type="text"
              id="address_line_2"
              name="address_line_2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address_line_2}
              isInvalid={
                (formik.touched.address_line_2 ?? false) &&
                !(formik.errors.address_line_2 == null)
              }
            />
            {(formik.touched.address_line_2 ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_line_2}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="landmark">Landmark</Form.Label>
            <Form.Control
              type="text"
              id="landmark"
              name="landmark"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.landmark}
              isInvalid={
                (formik.touched.landmark ?? false) &&
                !(formik.errors.landmark == null)
              }
            />
            {(formik.touched.postcode ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.landmark}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="status_id">Status</Form.Label>
            <Form.Select
              as="select"
              id="status_id"
              name="status_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status_id}
              isInvalid={
                (formik.touched.status_id ?? false) &&
                !(formik.errors.status_id == null)
              }>
              <option disabled value="">
                --Select Status--
              </option>
              <option value={variables.ACTIVE_VALUE}>Active</option>
              <option value={variables.INACTIVE_VALUE}>Inactive</option>
            </Form.Select>
            {(formik.touched.status_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.status_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={12} className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <Button variant="primary" id="add-btn" type="submit">
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default WareHouseEditUser;
