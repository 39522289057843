import React, {useMemo, useState, useEffect} from 'react';

import {Dropdown} from 'react-bootstrap';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import OrderTableContainer from './OrderTableContainer';
import {ORDER_STATUS_VALUE} from 'Common/constants/layout';
import OrderStatusModal from './OrderStatus';
import {useLocation} from 'react-router-dom';

interface OrderListType {
  id: number;
  order: {
    id: number;
    customer_id: string;
    created_at: string;
    order_status: string;
    payment_type: string;
    final_total_amount: number;
    customer_address: {
      city_name: string;
      customer_first_name: string;
      customer_last_name: string;
      phone_number: string;
      state_name: string;
      customer_fullname: string;
      customer_email: string;
    };
  };
  order_no: number;
  order_status: string;
  // customer_address: {
  //   customer_first_name: string;
  //   customer_last_name: string;
  //   city_name: string;
  //   phone_number: string;
  // };
  // customer_id: string;
  total_amount: number;
  // order_status: string;
  // created_at: string;
  // payment_type: string;
}
const OrderListTable = (): JSX.Element => {
  const [orderList, setOrderList] = React.useState<OrderListType[] | null>(
    null,
  );
  const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  // console.log('🚀 ~ OrdersList ~ status:', status);
  const defaultPage = 1;
  const [orderDetails, setOrderDetails] = useState<any>({});

  const [isStatusUpdated, setIsStatusUpdated] = useState(false);

  const [pageValue, setPageValue] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState('id');
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRecords, setTotalRecords] = useState<number>(defaultPage);
  const currentData = Math.ceil(totalRecords / pageSize);
  // console.log('🚀 ~ OrderListTable ~ currentData:', pageValue);
  const [orderStatusFilter, setOrderStatusFilter] = useState<string | ''>(
    status ?? '',
  );
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<number | ''>('');

  useEffect(() => {
    void getUsersList();
  }, [
    pageValue,
    pageSize,
    orderStatusFilter,
    paymentTypeFilter,
    isStatusUpdated,
    search,
  ]);

  async function getUsersList(): Promise<void> {
    try {
      const res = await ApiUtils.getOrderList(
        `?page=${pageValue}&page_size=${pageSize}&sort_column=${sortColumn}&sort_direction=${sortDirection}&order_status=${orderStatusFilter}&payment_type=${paymentTypeFilter}&search=${search}`,
      );
      const mappedData = (res as any)?.data?.data?.map(
        (order: OrderListType) => {
          return {
            id: order?.id,
            customer_id: order?.order?.customer_id,
            city_name: order?.order?.customer_address?.city_name,
            final_total_amount: order?.total_amount,
            order_status: order?.order_status,
            phone_number: order?.order?.customer_address?.phone_number,
            order_date: order?.order?.created_at.split(' ')[0],
            payment_type: order?.order?.payment_type,
            order_number: order?.order_no,
            customer_fullname:
              order?.order?.customer_address !== null
                ? order?.order?.customer_address?.customer_first_name +
                  ' ' +
                  order?.order?.customer_address?.customer_last_name
                : '-',
            customer_email: order?.order?.customer_address?.customer_email,
            state_name: order?.order?.customer_address?.state_name,
          };
        },
      );

      setOrderList(mappedData);
      setTotalRecords((res as any)?.data?.total ?? defaultPage);
    } catch (error: any) {
      toast.error(error.message);
    }
  }
  const handleOrderStatusFilter = (orderId: any): void => {
    setOrderStatusFilter(orderId);
  };
  const handlePaymentTypeFilter = (paymentId: any): void => {
    setPaymentTypeFilter(paymentId);
  };

  const handleNextPagination = (pageSize: any): void => {
    if (currentData > 0 && currentData > pageSize) {
      setPageValue(pageSize + 1);
    }
  };

  const handlePrevPagination = (pageSize: any): void => {
    if (pageSize > 1 && currentData >= pageSize) {
      setPageValue(pageSize - 1);
    }
  };
  const handleDeleteOrder = (): void => {
    void Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (result.isConfirmed) {
        void Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success',
        });
      }
    });
  };

  const handleSortByColumn = async (column: string): Promise<void> => {
    try {
      let newSortDirection = 'asc';

      if (column === sortColumn) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        newSortDirection = 'desc';
      }

      const res = await ApiUtils.getOrderList(
        `?page=${pageValue}&page_size=${pageSize}&sort_column=${column}&sort_direction=${newSortDirection}&order_status=${orderStatusFilter}&payment_type=${paymentTypeFilter}&search=${search}`,
      );
      const mappedData = (res as any)?.data?.data?.map(
        (order: OrderListType) => {
          return {
            id: order?.id,
            customer_id: order?.order?.customer_id,
            city_name: order?.order?.customer_address?.city_name,
            final_total_amount: order?.total_amount,
            order_status: order?.order_status,
            phone_number: order?.order?.customer_address?.phone_number,
            customer_fullname:
              order?.order?.customer_address !== null
                ? order?.order?.customer_address?.customer_first_name +
                  ' ' +
                  order?.order?.customer_address?.customer_last_name
                : '-',
            customer_email: order?.order?.customer_address?.customer_email,
            order_date: order?.order?.created_at.split(' ')[0],
            payment_type: order?.order?.payment_type,
            order_number: order?.order_no,
            state_name: order?.order?.customer_address?.state_name,
          };
        },
      );

      setSortDirection(newSortDirection);
      setOrderList(mappedData);
      setSortColumn(column);
      setTotalRecords((res as any)?.data?.total ?? defaultPage);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const cancelOrder = async (cellProps: any): Promise<void> => {
    void Swal.fire({
      title: 'Are you sure?',
      text: 'Want to cancel this order?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
    }).then(async (result: any) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (result.isConfirmed) {
        try {
          const res: any = await ApiUtils.cancelOrder(
            `/${cellProps.order_number}`,
          );
          toast.success(res.message);
          await getUsersList();
        } catch (error: any) {
          toast.error(error.message);
        }
      }
    });
  };
  const [invoiceUrl, setInvoiceUrl] = useState<any>(null);

  const downloadInvoice = async (cellProps: any): Promise<void> => {
    try {
      const res: any = await ApiUtils.downloadShiprocketInvoice(
        `/${cellProps.order_number}`,
      );
      const invoiceURL: any = res.data?.invoice_url;
      if (invoiceURL !== undefined) {
        setInvoiceUrl(invoiceURL);
        toast.success(res.message);
      } else {
        toast.error('Invoice not available');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const shiprocketTracker = async (cellProps: any): Promise<void> => {
    try {
      const res: any = await ApiUtils.shiprocketOrderUrl(
        `/${cellProps.order_number}`,
      );

      const shiprocketOrderUrl = res.data?.url;

      // toast.success(res.message);
      if (shiprocketOrderUrl !== '') {
        window.open(shiprocketOrderUrl, '_blank', 'noopener,noreferrer');
        toast.success(res.message);
      } else {
        toast.error('Ship order URL not available');
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    if (invoiceUrl != null) {
      // Automatically navigate to the PDF URL to download it
      window.location.href = invoiceUrl;
    }
  }, [invoiceUrl]);
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Order Number',
        accessor: 'order_number',
        disableFilters: true,
        filterable: true,
        Cell: ({cell}: {cell: {value: string}}) => {
          return <div>{cell.value}</div>;
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_fullname',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{cell.value !== undefined ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Customer Email',
        accessor: 'customer_email',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Customer Phone Number',
        accessor: 'phone_number',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },

      {
        Header: 'Date',
        accessor: 'order_date',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'City Name',
        accessor: 'city_name',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'State Name',
        accessor: 'state_name',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Total Amount',
        accessor: 'final_total_amount',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Payment Method',
        accessor: 'payment_type',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          switch (cellProps?.value) {
            case 21:
              return (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  {' '}
                  Cash
                </span>
              );
            case 22:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  Online Payment
                </span>
              );
            default:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  NA
                </span>
              );
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'order_status',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          switch (cellProps?.value) {
            case ORDER_STATUS_VALUE.PENDING:
              return (
                <span className="badge bg-warning-subtle text-warning text-uppercase">
                  {' '}
                  {cellProps?.value}
                </span>
              );
            case ORDER_STATUS_VALUE.ACCEPTED:
              return (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  {cellProps?.value}
                </span>
              );
            case ORDER_STATUS_VALUE.DELIVERY_PERSON_ASSIGN:
              return (
                <span className="badge bg-info-subtle text-info text-uppercase">
                  {cellProps?.value}
                </span>
              );
            case ORDER_STATUS_VALUE.DELIVERED:
              return (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  {cellProps?.value}
                </span>
              );
            case ORDER_STATUS_VALUE.ON_THE_WAY:
              return (
                <span className="badge bg-secondary-subtle text-secondary text-uppercase">
                  {cellProps?.value}
                </span>
              );
            case ORDER_STATUS_VALUE.CANCELED:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  {cellProps?.value}
                </span>
              );
            default:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  {cellProps?.value}
                </span>
              );
          }
        },
      },

      {
        Header: 'Action',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return (
            <Dropdown className="text-center">
              <Dropdown.Toggle className="btn btn-soft-secondary btn-sm btn-icon dropdown arrow-none">
                <i className="mdi mdi-dots-horizontal" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item
                    href={`/order-detail/${
                      cellProps.order_number !== null
                        ? cellProps.order_number
                        : cellProps.id
                    }`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{' '}
                    View Order
                  </Dropdown.Item>
                </li>
                <li className="d-none">
                  <Dropdown.Item
                    href={`/order-invoice-detail/${
                      cellProps.order_number !== null
                        ? cellProps.order_number
                        : cellProps.id
                    }`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{' '}
                    View Invoice
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    className="remove-list"
                    onClick={async () => {
                      await shiprocketTracker(cellProps);
                    }}>
                    <i className="ri-truck-fill align-bottom me-2 text-muted" />{' '}
                    Ship Order
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    className="remove-list"
                    onClick={async () => {
                      await downloadInvoice(cellProps);
                    }}>
                    <i className="ri-file-download-fill align-bottom me-2 text-muted" />
                    Invoice Download
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    className="remove-list"
                    onClick={async () => {
                      await cancelOrder(cellProps);
                    }}>
                    <i className="ri-close-circle-fill align-bottom me-2 text-muted" />
                    Cancel Order
                  </Dropdown.Item>
                </li>

                <li className="d-none">
                  <Dropdown.Item
                    onClick={async () => {
                      setOrderDetails(cellProps);
                      toogleStatus();
                      setIsStatusUpdated(false);
                    }}
                    href="#"
                    className="remove-list">
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                    Update Order
                  </Dropdown.Item>
                </li>
                <li className="d-none">
                  <Dropdown.Item
                    onClick={handleDeleteOrder}
                    href="#"
                    className="remove-list">
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />
                    Delete
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [],
  );

  const [statusModal, setStatusModal] = useState(false);

  function toogleStatus(): void {
    setStatusModal(!statusModal);
  }
  const handleSearch = (value: string): void => {
    setSearch(value);
    setPageValue(defaultPage);
  };

  return (
    <>
      <OrderTableContainer
        columns={columns ?? []}
        data={orderList ?? []}
        isGlobalFilter={true}
        handleNextPagination={handleNextPagination}
        handlePrevPagination={handlePrevPagination}
        pageValue={pageValue}
        iscustomPageSize={false}
        isBordered={true}
        customPageSize={pageSize}
        pageSize={pageSize}
        currentData={currentData}
        setPageSize={setPageSize}
        className="custom-header-css"
        tableClass="table-centered align-middle table-nowrap mb-0"
        theadClass="text-muted table-light"
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        handleSortByColumn={handleSortByColumn}
        handleOrderStatusFilter={handleOrderStatusFilter}
        handlePaymentTypeFilter={handlePaymentTypeFilter}
        setOrderStatusFilter={setOrderStatusFilter}
        SearchPlaceholder="Search Order Number..."
        onSearch={handleSearch}
      />
      <OrderStatusModal
        toogleStatus={toogleStatus}
        details={orderDetails}
        showModal={statusModal}
        setIsStatusUpdated={setIsStatusUpdated}
      />
    </>
  );
};

export default OrderListTable;
