enum LAYOUT_TYPES {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  TWOCOLUMN = 'twocolumn',
}

enum LAYOUT_MODE_TYPES {
  LIGHTMODE = 'light',
  DARKMODE = 'dark',
}

enum LAYOUT_SIDEBAR_TYPES {
  LIGHT = 'light',
  DARK = 'dark',
  GRADIENT = 'gradient',
  GRADIENT_2 = 'gradient-2',
  GRADIENT_3 = 'gradient-3',
  GRADIENT_4 = 'gradient-4',
}

enum LAYOUT_WIDTH_TYPES {
  FLUID = 'lg',
  BOXED = 'boxed',
}

enum LAYOUT_POSITION_TYPES {
  FIXED = 'fixed',
  SCROLLABLE = 'scrollable',
}

enum LAYOUT_TOPBAR_THEME_TYPES {
  LIGHT = 'light',
  DARK = 'dark',
  BRAND = 'brand',
}

enum LEFT_SIDEBAR_SIZE_TYPES {
  DEFAULT = 'lg',
  COMPACT = 'md',
  SMALLICON = 'sm',
  SMALLHOVER = 'sm-hover',
}

enum LEFT_SIDEBAR_VIEW_TYPES {
  DEFAULT = 'default',
  DETACHED = 'detached',
}

enum LEFT_SIDEBAR_IMAGE_TYPES {
  NONE = 'none',
  IMG1 = 'img-1',
  IMG2 = 'img-2',
  IMG3 = 'img-3',
  IMG4 = 'img-4',
}

enum PERLOADER_TYPES {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

enum BODY_IMAGE_TYPES {
  NONE = 'none',
  IMG1 = 'img-1',
  IMG2 = 'img-2',
  IMG3 = 'img-3',
}

enum DOCUMENT_TITLE {
  DASHBOARD = 'Dashboard | Admin ',
  PRODUCT_LIST = 'Product List | Admin',
  COUPONS = 'Coupons | Admin',
  WARE_HOUSE_LIST = 'Warehouse List | Admin',
  INVOICE_LIST = 'Invoice List | Admin',
  CREATE_INVOICE = 'Create Invoice | Admin',
  INVOICE_DETAILS = 'Invoice Details | Admin',
  LOGIN = 'Login | Admin',
  LOGOUT = 'Log Out | Admin',
  FORGOT_PASSWORD = 'Forgot Password | Admin',
  CHANGE_PASSWORD = 'Change Password | Admin',
  NOTIFY_ME = 'Notify Me | Admin',
  PAYMENT_TRACKING = 'Payment Tracking | Admin',
  STOCK_MANAGEMENT = 'Stock Management | Admin',
  USER_LIST = 'User List | Admin',
  REVIEWS_RATINGS = 'Reviews & Ratings | Admin',
  COUPON_ANALYSIS = 'Coupon Analysis | Admin',
}

enum ORDER_STATUS {
  PENDING = 11,
  ACCEPTED = 12,
  DELIVERY_PERSON_ASSIGN = 13,
  DELIVERED = 15,
  ON_THE_WAY = 14,
  CANCELED = 16,
}

enum ORDER_STATUS_VALUE {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  DELIVERY_PERSON_ASSIGN = '13',
  DELIVERED = 'DELIVERED',
  ON_THE_WAY = 'Pickup Scheduled',
  CANCELED = 'Canceled',
  SHIPPED = 'Shipped',
  READY_TO_SHIP = 'Ready To Ship',
}

// enum SUPPORT_STATUS {
//   PENDING = 29,
//   IN_PROGRESS = 30,
//   COMPLETED = 31,
//   OPEN = 32,
//   CLOSED = 33,
// }
enum SUPPORT_STATUS {
  PENDING = 30,
  OPEN = 31,
  CLOSED = 32,
}

export {
  LAYOUT_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_SIDEBAR_TYPES,
  LAYOUT_WIDTH_TYPES,
  LAYOUT_POSITION_TYPES,
  LAYOUT_TOPBAR_THEME_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_SIDEBAR_VIEW_TYPES,
  LEFT_SIDEBAR_IMAGE_TYPES,
  PERLOADER_TYPES,
  BODY_IMAGE_TYPES,
  DOCUMENT_TITLE,
  ORDER_STATUS,
  ORDER_STATUS_VALUE,
  SUPPORT_STATUS,
};
