const apiEndpoints = {
  LOGIN: '/api/admin/login',
  GET_PROFILE: '/api/admin/get-admin-profile',
  VERIFY_OTP: '/api/admin/verify-otp',
  FORGET_PASSWORD: '/api/admin/send-reset-link',
  RESET_PASSWORD: '/api/admin/reset-password',
  GET_POLICY_LIST: '/api/admin/get-policy',
  DELETE_POLICY: '/api/admin/delete-policy',
  ADD_POLICY: '/api/admin/create-policy',
  GET_POLICY_BY_ID: '/api/admin/get-policy',
  WAREHOUSE_REGISTER: '/api/admin/create-warehouse',
  UPDATE_POLICY: '/api/admin/update-policy',
  GET_COUPON_MEDIA_TYPE: '/api/common/get-coupon-and-media-type',
  GET_WAREHOUSELIST: '/api/admin/get-warehouse?sort_direction=desc',
  DELETE_WAREHOUSE: '/api/admin/delete-warehouse',
  UPDATE_WAREHOUSE: '/api/admin/update-warehouse',
  GET_ORDERLIST: '/api/common/get-order',
  GET_PAYMENT_TRACKING: '/api/common/get-order-transaction',
  GET_CATEGORY: '/api/common/get-category?type=Dropdown',
  GET_STOCK: '/api/common/get-stock',
  GET_PRODUCT_BY_CATEGORY_ID: '/api/common/get-product-by-category-id',
  GET_OPTION_BY_PRODUCT_ID: '/api/common/get-option-by-product-id',
  GET_OPTION_VALUE_BY_OPTION_ID:
    '/api/common/get-option-value-by-pro-option-id',
  CREATE_COUPON: '/api/common/create-coupon',
  UPDATE_COUPON: '/api/common/update-coupon',
  LIST_COUPON: '/api/common/list-coupon',
  DELETE_COUPON: '/api/common/delete-coupon',
  GET_AREA_SPECIFIC_SALES: '/api/admin/list-area-specific-sale',
  GET_SUPPORT_TICKET_LIST: '/api/admin/list-support-ticket',
  UPDATE_TICKET_STATUS: '/api/admin/update-ticket-status',
  SUPPORT_CHAT_BY_TICKET: '/api/admin/list-support-chat-by-ticket',
  DELETE_SUPPORT_TICKET: '/api/admin/delete-support-ticket',
  CHATTING_SUPPORT: '/api/admin/store-support-chat',
  GET_DASHBOARD_DATA: '/api/common/get-dashboard-data',
  GET_PRODUCT: '/api/common/get-product',
  GET_STATUS: '/api/common/get-status',
  GET_CUSTOMER_LIST: '/api/admin/get-customer',
  UPDATE_CUSTOMER: '/api/admin/update-customer',
  DELET_CUSTOMER: '/api/admin/delete-customer',
  GET_COUPON_ANALYSIS: '/api/common/coupon-analysis',
  GET_WISHLISTED_ITEM: '/api/common/get-wishlist',
  GET_NOTIFIED_ITEM: '/api/common/get-notify',
  ORDER_COUNT: '/api/common/order-count',
  REFUND_PAYMENT: '/api/admin/refund-payment',
  GET_DISCOUNT: '/api/common/get-product-discount?sort_direction=desc',
  CREATE_DISCOUNT: '/api/common/create-product-discount',
  UPDATE_DISCOUNT: '/api/common/update-product-discount',
  DELETE_DISCOUNT: '/api/common/delete-product-discount',
  UPDATE_ORDER_STATUS: '/api/common/update-order-status',
  GET_SALES_REPORT: '/api/common/sales-report',
  CITY: '/api/common/get-city',
  COUNTRIES: '/api/common/get-country',
  STATE: '/api/common/get-state',
  CANCEL_ORDER: '/api/common/cancel-order',
  DOWNLOAD_INVOICE: '/api/common/download-shiprocket-invoice',
  SHIPMENT_STATUS_TRACK: '/api/common/track-order',
  SHIP_ORDER_URL: '/api/common/ship-order-url',
};

export {apiEndpoints};
