import React, {useState, useMemo, useEffect} from 'react';
import {Card, Container, Offcanvas, Dropdown, Row, Col} from 'react-bootstrap';
import TableContainer from 'Common/TableContainer';
import Swal from 'sweetalert2';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';
import StatusModal from './StatusModal';
import CommentModal from './CommentModal';
import {Link} from 'react-router-dom';
import {SUPPORT_STATUS} from 'Common/constants/layout';
import RefundModal from './RefundModal';
const SupportListing = (): JSX.Element => {
  document.title = 'Customer Support | Admin';
  const defaultPage = 1;
  const [pageValue, setPageValue] = useState(defaultPage);
  const [sortColumn, setSortColumn] = useState('id');

  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState('asc');
  const [totalRecords, setTotalRecords] = useState(defaultPage);
  const currentData = Math.ceil(totalRecords / 10);
  const [list, setList]: any[] = useState();
  const [showCoupons, setShowCoupons] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [statusModal, setStatusModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [resetSearchFlag, setResetSearchFlag] = useState(false);

  const handleSearch = (value: string): any => {
    setSearchValue(value);
    setPageValue(defaultPage);
  };
  const fetchSupportTicketList = async (): Promise<void> => {
    try {
      const response: any = await ApiUtils.getSupportTicketList(
        `page=${pageValue}&page_size=${pageSize}&search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}`,
      );
      setList(response?.data?.data);
      setTotalRecords(response.data.total);
    } catch (err) {
      toast.error('Something went wrong');
    }
  };
  useEffect(() => {
    void fetchSupportTicketList();
  }, [pageValue, pageSize, statusModal, searchValue]);
  const handleDelete = (data: any): any => {
    void Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async result => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (result.isConfirmed) {
        await ApiUtils.deleteSupportTicket(`id=${data.id}`)
          .then((res: any) => {
            setResetSearchFlag(true);
            setSearchValue('');
            void fetchSupportTicketList();
            void Swal.fire({
              title: 'Deleted!',
              text: res?.message,
              icon: 'success',
            });
          })
          .catch((err: any) => {
            toast.error(err?.response?.data?.message);
          });
      }
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: ' Ticket No',
        disableFilters: true,
        filterable: true,
        accessor: 'ticket_no',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Issue Type',
        accessor: 'issue_type',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          // return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
          switch (cell?.value) {
            case 1:
              return <span className="">Technical Issue</span>;
            case 2:
              return <span className="">Refund Issue</span>;
            case 3:
              return <span className="">Delivery Issue</span>;
            case 4:
              return <span className="">Other</span>;
          }
        },
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        disableFilters: true,
        filterable: true,
        Cell: (cell: any) => {
          return (
            <div className="text-wrap">
              {(cell.value as boolean) ? cell.value : '-'}
            </div>
          );
        },
      },
      {
        Header: 'Conclusion',
        accessor: 'conclusion',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <span>
              <span className="badge bg-light text-body fs-12 fw-medium">
                {cellProps?.value != null ? cellProps.value : '-'}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Customer',
        disableFilters: true,
        filterable: true,
        accessor: 'first_name',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Customer Email',
        disableFilters: true,
        filterable: true,
        accessor: 'email',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },
      {
        Header: 'Customer Number',
        disableFilters: true,
        filterable: true,
        accessor: 'phone',
        Cell: (cell: any) => {
          return <div>{(cell.value as boolean) ? cell.value : '-'}</div>;
        },
      },

      {
        Header: 'Status',
        accessor: 'status_id',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          switch (cellProps?.value) {
            case SUPPORT_STATUS.PENDING:
              return (
                <span className="badge bg-warning-subtle text-warning text-uppercase">
                  pending
                </span>
              );
            // case SUPPORT_STATUS.IN_PROGRESS:
            //   return (
            //     <span className="badge bg-success-subtle text-success text-uppercase">
            //       inprogress
            //     </span>
            //   );
            // case SUPPORT_STATUS.COMPLETED:
            //   return (
            //     <span className="badge bg-success-subtle text-success text-uppercase">
            //       completed
            //     </span>
            //   );
            case SUPPORT_STATUS.OPEN:
              return (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  open
                </span>
              );
            case SUPPORT_STATUS.CLOSED:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  closed
                </span>
              );
            default:
              return (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  Unknown
                </span>
              );
          }
        },
      },

      {
        Header: 'Action',
        disableFilters: true,
        filterable: false,
        Cell: (cellProps: any) => {
          return (
            <Dropdown className="text-start">
              <Dropdown.Toggle className="btn btn-soft-secondary btn-sm btn-icon dropdown arrow-none">
                <i className="mdi mdi-dots-horizontal" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item className="remove-list">
                    <Link to={`/support-chat/${cellProps.row.original.id}`}>
                      <i className="ri-eye-fill align-bottom me-2 text-muted" />
                      View Ticket
                    </Link>
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={async () => {
                      setCustomerDetails(cellProps.row.original);
                    }}
                    className="remove-list">
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                    Update Status
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={() => {
                      setRefundModal(true);
                    }}
                    className="remove-list">
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                    Refund
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={() => handleDelete(cellProps.row.original)}
                    className="remove-list">
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />
                    Delete
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [showCoupons],
  );
  useEffect(() => {
    if (Object.keys(customerDetails).length > 0) {
      toogleStatus();
    }
  }, [customerDetails]);
  function toogleStatus(): void {
    setStatusModal(!statusModal);
  }

  function toogleComment(): void {
    setCommentModal(!commentModal);
  }
  const handleNextPagination = (pageSize: any): void => {
    if (currentData > 0 && currentData > pageSize) {
      setPageValue(pageSize + 1);
    }
  };
  const handlePrevPagination = (pageSize: any): void => {
    if (pageSize > 1 && currentData >= pageSize) {
      setPageValue(pageSize - 1);
    }
  };
  const ticketList = list?.map((data: any) => {
    return {
      id: data.id,
      ticket_no: data.ticket_no,
      issue_type: data.issue_type,
      status_id: data.status_id,
      comment: data.comment,
      conclusion: data?.conclusion_message,
      first_name: data?.first_name + ' ' + data?.last_name,
      phone: data?.phone,
      email: data?.email,
    };
  });
  const handleSortByColumn = async (column: string): Promise<void> => {
    try {
      let newSortDirection = 'asc';

      if (column === sortColumn) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        newSortDirection = 'desc';
      }
      const response: any = await ApiUtils.getSupportTicketList(
        `page=${pageValue}&page_size=${pageSize}&search=${searchValue}&sort_column=${column}&sort_direction=${newSortDirection}`,
      );
      setList(response?.data?.data);
      setSortColumn(column);

      setSortDirection(newSortDirection);
      setTotalRecords(response.data.total);
    } catch (err) {
      toast.error('Something went wrong');
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">CUSTOMER SUPPORT</h4>
          </div>
          <Row>
            <Col xxl={12}>
              <Card>
                <Card.Body>
                  <TableContainer
                    columns={columns ?? []}
                    data={ticketList ?? []}
                    handleNextPagination={handleNextPagination}
                    handlePrevPagination={handlePrevPagination}
                    pageValue={pageValue}
                    customPageSize={pageSize}
                    isGlobalFilter={true}
                    isBordered={true}
                    iscustomPageSize={false}
                    currentData={currentData}
                    setPageSize={setPageSize}
                    className="custom-header-css"
                    tableClass="table-centered align-middle table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    SearchPlaceholder="Search Ticket..."
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handleSortByColumn={handleSortByColumn}
                    onSearch={handleSearch}
                    resetSearchFlag={resetSearchFlag}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <StatusModal
        toogleStatus={toogleStatus}
        showModal={statusModal}
        details={customerDetails}
      />
      <RefundModal showModal={refundModal} setShowModal={setRefundModal} />

      <CommentModal toogleComment={toogleComment} showModal={commentModal} />
      <Offcanvas
        show={showCoupons}
        onHide={() => {
          setShowCoupons(!showCoupons);
        }}
        placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Customer Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mt-3">
            <div className="table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td>
                      <span className="text-muted">Customer ID :</span>
                    </td>
                    <td>
                      <span className="fw-medium">{customerDetails.id}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-muted">Customer Issue :</span>
                    </td>
                    <td>
                      <span className="fw-medium">{customerDetails.title}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span className="text-muted">Ticket No:</span>
                    </td>
                    <td>
                      <span className="fw-medium">
                        {customerDetails.ticket_no}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-muted">Issue Type Id:</span>
                    </td>
                    <td>
                      <span className="fw-medium">
                        {customerDetails.issue_type_id}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-muted">Comment</span>
                    </td>
                    <td>
                      <span className="fw-medium">
                        {customerDetails.comment}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-muted">Status:</span>
                    </td>
                    <td>
                      <span
                        className={
                          customerDetails.status === 'Expired'
                            ? 'badge bg-danger-subtle text-danger text-uppercase'
                            : 'badge bg-success-subtle text-success text-uppercase'
                        }>
                        {customerDetails.status}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-muted">Message:</span>
                    </td>
                    <td>
                      <span className="fw-medium">
                        {customerDetails.message}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="text-muted">Conclusion:</span>
                    </td>
                    <td>
                      <span className="fw-medium">
                        {customerDetails.conclusion}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default SupportListing;
