import ModalContainer from 'Common/ModalContainer';
import React from 'react';
import {Col} from 'react-bootstrap';
interface FullImagePreviewerProps {
  toggleImagePreview: () => void;
  showModalImg: boolean;
  fullImageUrl: string;
}
const FullImagePreviewer: React.FC<FullImagePreviewerProps> = ({
  toggleImagePreview,
  showModalImg,
  fullImageUrl,
}) => {
  const modalData = (): JSX.Element => {
    const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];
    const allowedVideoExtensions = ['mp4', 'mov', 'webm'];
    const handleFileType = (url: string): string => {
      // Convert URL to lowercase to make it case-insensitive
      const lowerUrl = url.toLowerCase();
      // Check for image extension
      if (allowedImageExtensions.some(ext => lowerUrl.endsWith(ext))) {
        return 'image'; // Set the image tab
      }
      // Check for video extension
      if (allowedVideoExtensions.some(ext => lowerUrl.endsWith(ext))) {
        return 'video'; // Set the video tab
      }
      // If the extension is unknown or unsupported

      return 'unknown';
    };
    return (
      <Col lg={12}>
        {handleFileType(fullImageUrl) === 'image' ? (
          <img src={fullImageUrl} width="100%" height="auto" />
        ) : (
          <video
            src={fullImageUrl}
            controls
            width="100%"
            height="auto"
            autoPlay
            style={{
              objectFit: 'cover',
            }}
          />
        )}
      </Col>
    );
  };
  return (
    <div>
      <ModalContainer
        showModal={showModalImg}
        handleClose={toggleImagePreview}
        modalBody={modalData()}
      />
    </div>
  );
};

export default FullImagePreviewer;
